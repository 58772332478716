<script>
	import { _ } from 'svelte-i18n';
	import Slider from '@bulatdashiev/svelte-slider';
	import { createEventDispatcher } from 'svelte';
	import Cropper from 'svelte-easy-crop';
	import { getCroppedImg } from '../../utils/canvasUtil.js';
	import { notification } from '../../stores.js';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import FullPageModal from './FullPageModal.svelte';

	const dispatch = createEventDispatcher();

	let value = [1, 2];
	export let imgSrc;
	export let ajaxRequest = false;
	export let type = 'picture';

	let crop = { x: 0, y: 0 };
	$: zoom = value[0];

	let croppedImage;
	let pixelCrop;
	async function cropImage() {
		croppedImage = await getCroppedImg(imgSrc, pixelCrop, type);
	}

	function previewCrop(e) {
		pixelCrop = e.detail.pixels;
	}
</script>

<FullPageModal on:close={() => dispatch('cancel')}>
	<div class="relative h-96 w-full">
		<Cropper
			image={imgSrc}
			bind:crop
			bind:zoom
			aspect={type == 'banner' ? 3 / 1 : 1}
			showGrid={false}
			on:cropcomplete={previewCrop}
		/>
	</div>

	<div class="flex items-center w-full">
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			class="mr-2"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05057 16 10.0909 15.7931 11.0615 15.391C11.7226 15.1172 12.3425 14.7565 12.9054 14.3196L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L14.3196 12.9054C14.7565 12.3425 15.1172 11.7226 15.391 11.0615C15.7931 10.0909 16 9.05057 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM5 7C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H11C11.5523 9 12 8.55229 12 8C12 7.44772 11.5523 7 11 7H5Z"
				fill="currentColor"
			/>
		</svg>
		<Slider bind:value min={1} max={2} step={0.1} />
		<svg
			width="20"
			height="20"
			class="ml-2"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05057 16 10.0909 15.7931 11.0615 15.391C11.7226 15.1172 12.3425 14.7565 12.9054 14.3196L18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L14.3196 12.9054C14.7565 12.3425 15.1172 11.7226 15.391 11.0615C15.7931 10.0909 16 9.05057 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0ZM9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5V7H5C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9H7V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H9V5Z"
				fill="currentColor"
			/>
		</svg>
	</div>

	<div class="flex items-center justify-between w-full mt-8">
		<button on:click={() => dispatch('cancel')} class="focus:underline hover:underline w-24"
			>{$_('Cancel')}</button
		>
		<button
			disabled={ajaxRequest}
			on:click={async () => {
				try {
					ajaxRequest = true;
					await cropImage();
					dispatch('upload', croppedImage);
				} catch (err) {
					console.error(err);
					ajaxRequest = false;
					$notification = {
						text: $_('Something went wrong. Please try again later.'),
						type: 'error'
					};
				}
			}}
			class="btn-background w-32 h-9 inline-flex items-center justify-center"
		>
			{#if ajaxRequest}
				<SpinnerIcon css="h-5 w-5" />
			{:else}
				{$_('OK')}
			{/if}
		</button>
	</div>
</FullPageModal>
